.rpb-root{display:inline-flex;flex-direction:column}.rpb-root ul{flex-wrap:wrap;list-style:none;margin:0;padding:0}.rpb-item,.rpb-root ul{display:flex;justify-content:center}.rpb-item{align-items:center;-webkit-appearance:none;appearance:none;background:#fff;border:0;border-radius:.375rem;box-shadow:0 1px 1px rgba(0,0,0,.09);cursor:pointer;font-size:1rem;height:2.4rem;margin-left:.25rem;margin-right:.25rem;min-width:2.4rem;outline:2px solid transparent;outline-offset:2px;padding-left:.75rem;padding-right:.75rem;position:relative;transition:all .15s ease;-webkit-user-select:none;-ms-user-select:none;user-select:none;white-space:nowrap;width:auto;will-change:transform}.rpb-item--disabled{opacity:.4}.rpb-item--active{background-color:#4c78ff;color:#fff}.rpb-progress{background-color:#7597ff;border-radius:5px;box-shadow:0 0 4px rgba(127,177,255,.6);height:3px;margin:1rem 0;max-width:100%;transition:width .2s ease;width:0}.rpb-go-item{-webkit-padding-end:.75rem;-webkit-padding-start:.75rem;align-items:center;background-color:#fff;border-radius:.375rem;box-shadow:0 2px 1px rgba(0,0,0,.04);display:flex;font-size:1rem;height:2.4rem;padding-inline-end:.75rem;padding-inline-start:.75rem;width:70px}.rpb-go-item input{border:1px solid rgba(0,0,0,.1);border-radius:4px;height:20px;width:100%}
/*# sourceMappingURL=index.css.map */
/*
 * react-circular-progressbar styles
 * All of the styles in this file are configurable!
 */

.CircularProgressbar {
  /*
   * This fixes an issue where the CircularProgressbar svg has
   * 0 width inside a "display: flex" container, and thus not visible.
   */
  width: 100%;
  /*
   * This fixes a centering issue with CircularProgressbarWithChildren:
   * https://github.com/kevinsqi/react-circular-progressbar/issues/94
   */
  vertical-align: middle;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #3e98c7;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: #d6d6d6;
  /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
  stroke-linecap: round;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: #3e98c7;
  font-size: 20px;
  dominant-baseline: middle;
  text-anchor: middle;
}

.CircularProgressbar .CircularProgressbar-background {
  fill: #d6d6d6;
}

/*
 * Sample background styles. Use these with e.g.:
 *
 *   <CircularProgressbar
 *     className="CircularProgressbar-inverted"
 *     background
 *     percentage={50}
 *   />
 */
.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-background {
  fill: #3e98c7;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-text {
  fill: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-path {
  stroke: #fff;
}

.CircularProgressbar.CircularProgressbar-inverted .CircularProgressbar-trail {
  stroke: transparent;
}

